@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  margin: 0;
  padding: 0;
}

body,
#root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

#root {
  font-family: 'Outfit', 'Century Gothic', 'Helvetica Neue', sans-serif, -apple-system, BlinkMacSystemFont;
  justify-content: center;
  align-items: center;
  text-align: center;
}

h1 {
  font-family: 'Outfit', 'Century Gothic', 'Helvetica Neue', sans-serif, -apple-system, BlinkMacSystemFont;
  font-size: 2em;
  font-weight: bold;
}

body {
  margin: 0;
  font-family: 'Outfit', 'Century Gothic', 'Helvetica Neue', sans-serif, -apple-system, BlinkMacSystemFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.amplify-button {
  width: 15rem;
  display: flex;
  justify-content: center;
  text-align: center;
  background: var(--amplify-colors-black);
  cursor: pointer;
  --amplify-components-button-hover-border-color: var(--amplify-colors-black);
}

.amplify-button--primary {
  background-color: var(--amplify-colors-black) !important;
}

.amplify-button--link {
  color: black !important;
  --amplify-components-button-link-hover-background-color: var(--amplify-colors-transparent);
  --amplify-components-button-link-hover-color: var(--amplify-colors-black);
}

.amplify-heading--4 {
  display: flex;
  justify-content: center;
}

.amplify-authenticator__subtitle {
  display: flex;
  justify-content: center;
  text-align: center;
}

.amplify-tabs__item--active {
  background: var(--amplify-colors-black) !important;
  color: var(--amplify-colors-white) !important;
  border-color: unset !important;
  border-width: 0 !important;
  --amplify-components-tabs-item-hover-color: var(--amplify-colors-black) !important;
}

.amplify-tabs__item {
  border-color: var(--amplify-components-authenticator-router-border-color) !important;
  border-bottom-width: 1px !important;
}

.amplify-tabs__list--top {
  --internal-item-border-width: 0 !important;
}

.amplify-divider {
  --amplify-components-divider-border-color: var(--amplify-colors-black) !important;
}

.MuiSlider-root {
  color: rgba(0, 0, 0, 1) !important;
  bottom: -10px;
  padding: 0 20px;
  left: -10px
}

.MuiSlider-vertical {
  bottom: -35px;
  padding: 0 20px;
  left: 0px;
}

.MuiSlider-valueLabel:before {
  left: 50% !important;
  top: 70% !important;
  bottom: 0 !important;
  transform: translate(-50%, 50%) rotate(45deg) !important;
}

.MuiSlider-valueLabelOpen {
  transform: translateY(-100%) scale(1) !important;
}

.yarl__portal {
  z-index: 5000 !important;
}

.no-drag {
  user-select: none;
  pointer-events: none;
}
